import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Cart, Heart } from "react-bootstrap-icons";
import ProductCardHook from "../../../hooks/product/ProductCardHook";
import { toast } from "react-toastify";
import defaultImage from "../../../assets/images/default-store-350x350.jpg";
import { useNavigate } from "react-router-dom";
import TransHook from "./../../../hooks/translation/trans-hook";
import Cookies from "js-cookie";
import StarRatings from "react-star-ratings";
import calculateAndFormatPrice from "../../../hooks/GobalHooks.js/FormatPrice";
import "../CSS/ProductCard.css";
import GetPaymentMethodsHook from "../../../hooks/PaymentMethods/GetPaymentMethodsHook";
import baseURL, { baseURL2 } from "../../../api/GlobalData";

const ProductCard = ({ product }) => {
  const { handleCart, BuyNow, handleAddItem } = ProductCardHook();

  const { t } = TransHook();
  const navigate = useNavigate();
  const lang = localStorage.getItem("language");
  const cookieValue = Cookies.get("CompanyCurrency");
  const cur = cookieValue ? JSON.parse(cookieValue) : null;
  const [Pname, setPname] = useState("");
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (product) {
      lang === "ar"
        ? setPname(product?.nameAR)
        : lang === "tr"
        ? setPname(product?.nameTR)
        : setPname(product?.name);
    }
  }, [product, lang]);

  useEffect(() => {
    if (product?.currency) {
      // const filteredPrice = calculateAndFormatPrice(
      //   product?.ecommercePrice,
      //   product?.ecommercePriceAftereDiscount,
      //   Array.isArray(product?.currency)
      //     ? product.currency[0]?.exchangeRate
      //     : product?.currency?.exchangeRate
      // );
      //setPrice(filteredPrice);

      const price =
        product?.ecommercePriceAftereDiscount > 0
          ? product?.ecommercePriceAftereDiscount
          : product?.ecommercePriceMainCurrency > 0
          ? product?.ecommercePriceMainCurrency
          : product?.ecommercePrice;
      setPrice(price);
    }
  }, [product]);

  const { paymentMethods } = GetPaymentMethodsHook();
  const [onlinePrice, setOnlinePrice] = useState("");
  const [regularPrice, setRegularPrice] = useState(null);

  useEffect(() => {
    if (paymentMethods) {
      const onlinePaymentMethod = paymentMethods.find(
        (method) => method.name === "onlinePayment"
      );

      const extraCharge = onlinePaymentMethod?.extraCharge || 0;
      const companyRatio = onlinePaymentMethod?.companyRatio || 0;

      const extraChargeAmount = parseFloat(price) * (extraCharge / 100);
      const companyPortion = extraChargeAmount * (companyRatio / 100);
      const customerExtraCharge = extraChargeAmount - companyPortion;
      const finalPrice = parseFloat(price) + customerExtraCharge;

      setOnlinePrice(finalPrice);

      // Calculate the discounted price if it's higher than 0
      if (product?.ecommercePrice > 0) {
        // const regularPrice =
        //   parseFloat(product?.ecommercePrice) *
        //   (Array.isArray(product?.currency)
        //     ? product?.currency[0]?.exchangeRate
        //     : product?.currency?.exchangeRate);

        //setRegularPrice(regularPrice + customerExtraCharge);

        const price =
          product?.ecommercePriceAftereDiscount > 0
            ? product?.ecommercePriceAftereDiscount
            : product?.ecommercePriceMainCurrency > 0
            ? product?.ecommercePriceMainCurrency
            : product?.ecommercePrice;
        setRegularPrice(price + customerExtraCharge);
      }
    }
  }, [paymentMethods, price]);

  const screen = localStorage.getItem("screen") || "large";
  return (
    <div
      onClick={() =>
        navigate(`/product-details/${product?.slug || product?._id}`)
      }
      className={`product-default inner-quickview inner-icon p-4 cursor-pointer ${
        lang === "ar" ? "arabic-text" : ""
      }`}
    >
      <figure className="product-figure">
        <a style={{ width: "100%" }}>
          <img
            src={
              product.imagesArray && product?.imagesArray.length > 0
                ? product?.imagesArray[0]?.image.includes("https://")
                  ? product?.imagesArray[0]?.image
                  : `${baseURL}/product/${product?.imagesArray[0]?.image}`
                : defaultImage
            }
            style={{ width: "100%", objectFit: "scale-down" }}
            alt="product"
          />
        </a>
        <div
          className="btn-icon-group addToCart"
          onClick={(e) => {
            e.stopPropagation();
            //NOTE: Parasut quantity
            // const totalProductQuantity = product?.stocks?.reduce(
            //   (total, stock) => total + stock.productQuantity,
            //   0
            // );
            const totalProductQuantity = product.quantity;
            totalProductQuantity > 0
              ? handleCart(product)
              : toast.error(t("itemNotAvailable"));
          }}
        >
          <span
            className={`btn-icon cursor-pointer product-type-simple ${
              screen === "large" ? "" : "bg-white"
            }`}
          >
            <Cart />
          </span>
        </div>
        {screen === "large" && (
          <span
            style={{ cursor: "pointer" }}
            className={`btn-quickview ${lang === "ar" ? "arabic-text" : ""}`}
            title={t("buyNow")}
            onClick={(e) => {
              e.stopPropagation();
              BuyNow(product);
            }}
          >
            {t("buyNow")}
          </span>
        )}
      </figure>
      <div className="product-details">
        <div className="category-wrap">
          <div
            className={`category-list ${
              lang === "ar" ? "arabic-text text-right" : ""
            }`}
          >
            {product?.category ? (
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/products/${product?.category?._id}/category`);
                }}
                className="product-category mb-0 text-777"
              >
                {product?.category?.name}
              </p>
            ) : (
              <span className="product-category mb-0 text-777">
                {t("notCategorized")}
              </span>
            )}
          </div>
          <span>
            <Heart
              className="btn-icon-wish cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleAddItem(product?._id);
              }}
            />
          </span>
        </div>
        <div className="product-title">
          <a>
            {Pname?.length > (screen === "large" ? 50 : 30)
              ? Pname.substring(0, screen === "large" ? 50 : 30) + "..."
              : Pname}
          </a>
        </div>
        <div
          className="ratings-container position-absolute"
          style={{ bottom: "50px" }}
        >
          <StarRatings
            rating={product?.ratingsAverage}
            starRatedColor="#fe9900"
            starEmptyColor="lightgray"
            numberOfStars={5}
            starDimension="15px"
            starSpacing=".5px"
          />
        </div>

        <div className="price-box position-absolute" style={{ bottom: "10px" }}>
          <span className="product-price" style={{ fontSize: "20px" }}>
            {parseFloat(onlinePrice * cur?.exchangeRate).toFixed(2)}
            {cur?.currencyCode}
          </span>
          {product?.ecommercePriceAftereDiscount > 0 && (
            <del className="old-price mx-2">
              <span style={{ fontSize: "18px" }}>
                {regularPrice * cur?.exchangeRate}
                {cur?.currencyCode}
              </span>
            </del>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
