import { useEffect, useState } from "react";
import { ProductsEndPoint, WishlistEndPoint } from "../../api/GlobalData";
import useGetData from "../api/useGetData";
import usePostData from "./../api/usePostData";
import GetProductsHook from "./getProductsHook";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import GetPaymentMethodsHook from "../PaymentMethods/GetPaymentMethodsHook";
import { useAddCartItemMutation } from "../../RTK/Api/CartApi";
import useSanitizedHook from "../sanitizeHtml/useSanitizedHook";
import TransHook from "../translation/trans-hook";
import { toast } from "react-toastify";
import { useAddWishlistItemMutation } from "../../RTK/Api/WishlistApi";

const GetOneProductHook = (id) => {
  const navigate = useNavigate();
  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  const { isLoading, data, error } = useGetData(ProductsEndPoint + `/${id}`);

  // Log the error if existed
  if (error) console.error(error);

  const [quantity, setQuantity] = useState(0);

  // Set the sharing URL
  const url = `${window.location.href}`;
  const pageUrl = encodeURIComponent(url);
  const subject = encodeURIComponent("Check out this page");

  // Add to wishlist START
  const wishlistMutation = usePostData(WishlistEndPoint);
  const handleWishlist = () => {
    wishlistMutation.mutate({ productId: id });
  };

  // Set active tab (in product details like description, reviews...) START
  const [activeTab, setActiveTab] = useState("product-desc-content");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  // Set slides for the swiper depending on screen size START
  const [slides, setSlides] = useState(1920);
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 600) {
        setSlides(4);
      } else {
        setSlides(2);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Get top 10 sold products START
  const [top10Sold, setTop10Sold] = useState([{}]);
  const [productsData, isProductsLoading] = GetProductsHook();

  useEffect(() => {
    if (productsData) {
      const filteredProducts = productsData.filter(
        (product) => product.ecommerceActive && product.publish
      );
      const sortedProducts = [...filteredProducts].sort(
        (a, b) => b.sold - a.sold
      );
      const top10Products = sortedProducts.slice(0, 10);

      setTop10Sold(top10Products);
    }
  }, [productsData]);

  // Related products
  const [alternateProducts, setAlternateProducts] = useState([]);
  useEffect(() => {
    if (data?.data) setAlternateProducts(data?.data?.alternateProducts);
  }, [data]);

  const [maxAmount, setMaxAmount] = useState();
  const [inputValue, setInputValue] = useState(1);
  useEffect(() => {
    if (!isLoading && data?.data?.stocks) {
      //NOTE: Parasut quantity
      // const totalProductQuantity = data.data.stocks.reduce(
      //   (total, stock) => total + stock.productQuantity,
      //   0
      // );
      const totalProductQuantity = data?.data?.quantity;

      setMaxAmount(totalProductQuantity);

      setInputValue(Math.min(inputValue, totalProductQuantity));
    } else {
      setMaxAmount(1);
    }
  }, [isLoading, data]);

  // Handle quantity change START
  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      setInputValue(Math.min(newValue, maxAmount));
      setQuantity(event.target.value);
    }
  };

  const { paymentMethods } = GetPaymentMethodsHook();
  const [payments, setPayments] = useState([]);
  useEffect(() => {
    if (paymentMethods) {
      const activePayments = paymentMethods.filter(
        (method) => method.status === true
      );
      setPayments(activePayments);
    }
  }, [paymentMethods]);

  const { sanitizedShortDescription, sanitizedLongDescription } =
    useSanitizedHook(lang, data);
  const cur = JSON.parse(Cookies.get("CompanyCurrency"));

  const [addCartItem, { addItemError }] = useAddCartItemMutation();
  if (addItemError) toast.error(addItemError);

  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const data = Cookies.get("userData");
    if (data) {
      setUserData(JSON.parse(data));
    }

    window.scrollTo(0, 0);
  }, []);

  const [Pname, setPname] = useState("");
  const [price, setPrice] = useState();
  const [regularPrice, setRegularPrice] = useState(null);
  const [onlinePrice, setOnlinePrice] = useState("");
  useEffect(() => {
    if (data) {
      lang === "ar"
        ? setPname(data?.data?.nameAR)
        : lang === "tr"
        ? setPname(data?.data?.nameTR)
        : setPname(data?.data?.name);
    }
  }, [data, lang]);

  useEffect(() => {
    if (data?.data?.currency) {
      const price =
        data?.data?.ecommercePriceMainCurrency > 0
          ? data?.data?.ecommercePriceMainCurrency
          : parseFloat(data?.data?.ecommercePrice);
      setPrice(price);

      const onlinePaymentMethod = paymentMethods.find(
        (method) => method.name === "onlinePayment"
      );

      const extraCharge = onlinePaymentMethod?.extraCharge || 0;
      const companyRatio = onlinePaymentMethod?.companyRatio || 0;

      const extraChargeAmount = parseFloat(price) * (extraCharge / 100);
      const companyPortion = extraChargeAmount * (companyRatio / 100);
      const customerExtraCharge = extraChargeAmount - companyPortion;
      const finalPrice = parseFloat(price) + customerExtraCharge;

      setOnlinePrice(finalPrice);

      // Calculate the discounted price if it's higher than 0
      if (data?.data?.ecommercePrice > 0) {
        const regularPrice =
          data?.data?.ecommercePriceMainCurrency > 0
            ? data?.data?.ecommercePriceMainCurrency
            : parseFloat(data?.data?.ecommercePrice);
        setRegularPrice(regularPrice + customerExtraCharge);
      }
    }
  }, [data, paymentMethods]);

  const addToCart = (product) => {
    const currentCartItems = data?.data?.cartItems || [];

    const existingCartItem = currentCartItems.find(
      (item) => item.qr === product?.qr
    );
    const totalQuantityInCart = existingCartItem
      ? existingCartItem.quantity
      : 0;

    const availableStock = product.stocks.reduce(
      (total, stock) => total + stock.productQuantity,
      0
    );

    if (totalQuantityInCart + inputValue > availableStock) {
      toast.error(t("productOutOfStock"));
      return;
    }

    const cartItem = {
      qr: product?.qr,
      taxPrice: price,
      quantity: inputValue || 1,
      tax: {
        taxId: product.tax._id,
        taxRate: product.tax.tax.toString(),
      },
      profitRatio: product.profitRatio,
      user: userData?._id,
    };

    addCartItem(cartItem)
      .unwrap()
      .then(() => {
        toast.success(t("addedToCart"));
      })
      .catch((error) => {
        console.error("Failed to add to cart:", error);
        if (error?.data?.message == "Cannot add more than available stock") {
          toast.error(t("productOutOfStock"));
        } else {
          toast.error(t("failedToAddToCart"));
        }
      });
  };

  // Add to Wishlist functionality
  const [addWishlistItem, { error: addError }] = useAddWishlistItemMutation();
  if (addError) toast.error(addError);

  // Add to wishlist
  const handleAddItem = async (itemId) => {
    try {
      if (userData) {
        if (!isWishlisted) {
          await addWishlistItem({ productId: itemId })
            .unwrap()
            .then(() => {
              const userData = JSON.parse(Cookies.get("userData"));
              userData.wishlist.push(itemId);
              Cookies.set("userData", JSON.stringify(userData));
              toast.success(t("addedToWishlist"));
            });
        } else {
          toast.info(t("alreadyWishlisted"));
        }
      } else {
        toast.warn(t("pleaseLogin"));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const openReviews = () => setActiveTab("product-reviews-content");

  const [isWishlisted, setIsWishlisted] = useState(false);

  useEffect(() => {
    if (userData) {
      const userWishlist = userData.wishlist;
      if (userWishlist.includes(id)) {
        setIsWishlisted(true);
      }
    } else {
      setIsWishlisted(false);
    }
  }, [userData, id]);

  const calculateFinalPrice = (basePrice, extraCharge, companyRatio) => {
    const extraChargeAmount = parseFloat(basePrice) * (extraCharge / 100);
    const companyPortion = extraChargeAmount * (companyRatio / 100);
    const customerExtraCharge = extraChargeAmount - companyPortion;
    const finalPrice = parseFloat(basePrice) + customerExtraCharge;

    return finalPrice.toFixed(2);
  };

  return {
    data: data?.data,
    isLoading,
    quantity,
    pageUrl,
    subject,
    activeTab,
    slides,
    top10Sold,
    isProductsLoading,
    maxAmount,
    inputValue,
    alternateProducts,
    onlinePrice,
    Pname,
    cur,
    addItemError,
    sanitizedShortDescription,
    sanitizedLongDescription,
    payments,
    userData,
    price,
    isWishlisted,
    regularPrice,
    calculateFinalPrice,
    openReviews,
    handleAddItem,
    addToCart,
    navigate,
    handleWishlist,
    setActiveTab,
    handleTabClick,
    handleInputChange,
  };
};

export default GetOneProductHook;
