import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Dash, Plus, Trash2Fill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { ProductBaseUrl } from "../../api/GlobalData";
import TransHook from "../../hooks/translation/trans-hook";
import { toast } from "react-toastify";
import GetPaymentMethodsHook from "../../hooks/PaymentMethods/GetPaymentMethodsHook";

const CartItem = ({
  item,
  handleAddToCart,
  handleDecreaseCart,
  handleRemoveItem,
}) => {
  const { t } = TransHook();
  const cur = JSON.parse(Cookies.get("CompanyCurrency"));
  const lang = localStorage.getItem("language");

  const { paymentMethods } = GetPaymentMethodsHook();
  const [onlinePrice, setOnlinePrice] = useState("");
  useEffect(() => {
    if (paymentMethods) {
      const onlinePaymentMethod = paymentMethods.find(
        (method) => method.name === "onlinePayment"
      );
      const extraCharge = onlinePaymentMethod?.extraCharge || 0;
      const companyRatio = onlinePaymentMethod?.companyRatio || 0;

      const price =
        item?.product?.ecommercePriceMainCurrency > 0
          ? item?.product?.ecommercePriceMainCurrency
          : parseFloat(item?.taxPrice);

      const extraChargeAmount = price * (extraCharge / 100);
      const companyPortion = extraChargeAmount * (companyRatio / 100);
      const customerExtraCharge = extraChargeAmount - companyPortion;
      const finalPrice = price + customerExtraCharge;

      setOnlinePrice(finalPrice);
    }
  }, [paymentMethods]);

  const prodName =
    lang === "en"
      ? item?.product?.name
      : lang === "tr"
      ? item?.product?.nameTR
      : item?.product?.nameAR;

  return (
    <div className="d-flex cart-item-container">
      <div style={{ width: "20%", margin: "10px" }}>
        <Link to={`/product-details/${item?.product?.slug}`}>
          <img
            src={ProductBaseUrl + item?.product?.imagesArray[0]?.image}
            alt={prodName?.split(" ")[0]}
          />
        </Link>
      </div>

      <div style={{ width: "40%" }}>
        <h3>
          <Link
            to={`/product-details/${item?.product?.slug}`}
            className="fw-bold text-dark"
            title={prodName}
          >
            {prodName?.length > 30 ? prodName.slice(0, 30) + "..." : prodName}
          </Link>
        </h3>
        <p>
          {t("productCode")}: {item?.qr}
        </p>
      </div>
      <div
        className="price"
        style={{
          width: "20%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Dash
          className={`cursor-pointer ${
            item?.quantity > 1 ? "" : "cursor-not-allowed"
          } `}
          size={25}
          color="#fe9900"
          onClick={
            item?.quantity > 1
              ? () => handleDecreaseCart(item)
              : () => toast.error(t("cantDecrese"))
          }
        />
        <p className="d-flex cart-item-quantity">{item?.quantity}</p>
        <Plus
          className="cursor-pointer"
          size={25}
          onClick={() => handleAddToCart(item)}
          color="#fe9900"
        />
      </div>
      <div style={{ width: "20%" }}>
        <div
          className="Trash"
          onClick={() => handleRemoveItem(item?.product?._id)}
        >
          <Trash2Fill size={20} />
        </div>
        <h3 className="text-center mt-2">
          <span style={{ color: "#fe9900" }}>
            {cur?.currencyCode}
            {(onlinePrice * cur?.exchangeRate || 1 * item?.quantity).toFixed(2)}
          </span>
          <h5>
            {parseFloat(onlinePrice * cur?.exchangeRate || 1).toFixed(2)} ×{" "}
            {item?.quantity}
          </h5>
        </h3>
      </div>
    </div>
  );
};

export default CartItem;
