import React from "react";
import { useNavigate } from "react-router-dom";
import "./Slider.css";

const CatSliderNoImg = ({ Array: categories, limit }) => {
  const navigate = useNavigate();
  const handleButtonClick = (id) => {
    navigate(`/products/${id}/category`);
  };
  const lang = localStorage.getItem("language");
  const screen = localStorage.getItem("screen") || "large";

  return (
    <swiper-container
      slides-per-view={screen === "large" ? limit : 4}
      speed="500"
      loop="true"
    >
      {categories?.map((item, index) => {
        return (
          <swiper-slide key={item._id} className="swiperSlide category-list">
            <div
              className="Cat-info custom-nav-item"
              key={index}
              onClick={() => handleButtonClick(item?._id)}
            >
              {lang === "ar"
                ? item.nameAR
                : lang === "tr"
                ? item.nameTR
                : item.name}
            </div>
          </swiper-slide>
        );
      })}
    </swiper-container>
  );
};

export default CatSliderNoImg;
