const baseURL1 = "http://localhost:80";
export const baseURL = "https://api2.smartinb.ai:8001";
export const ProductBaseUrl = "https://api2.smartinb.ai:8001/product/";
export const ServerBaseUrl = "https://api2.smartinb.ai:8001/";
export const DataBaseName = "B202";
export const LogInEndPoint = "/api/auth/ecommerce-login";
export const SignUpEndPoint = "/api/auth/signup";
export const CategoriesEndPoint = "/api/category";
export const CartEndPoint = "/api/cart";
export const ProductsEndPoint = "/api/product";
export const ProductQuestionsEndPoint = "/api/questions";
export const WishlistEndPoint = "/api/wishlist";
export const CompanyInfoEndPoint = "/api/companyinfo";
export const CurrencyEndPoint = "/api/currency";
export const BrandsEndPoint = "/api/brand";
export const ContactEndPoint = "/api/ecommerceSettings/contactUs";
export const AddressEndPoint = "/api/addresses";
export const OrderEndPoint = "/api/ecommerce-order-router";
export const UpdatePasswordEndPoint = "/api/customars/updatePassword";
export const UpdateAccountEndPoint = "/api/customars/e-edit";
export const CmsPagesEndPoint = "/api/ecommerceSettings/page";
export const ReviewEndPoint = "/api/review";
export const LazyProductEndPoint = "/api/product/productLazy";
export const SliderEndPoint = "/api/ecommerceSettings/slider";
export const ForgotPasswordEndPoint = "/api/auth/forgotPasswords";
export const ResetPasswordEndPoint = "/api/auth/resetPassword";
export const ThirdPartyAuthEndPoint = "/api/thirdPartyAuth";
export const UsersEndPoint = "/api/users";
export const ShippingPricesEndPoint = "/api/shippingCompany";
export const PaymentMethodsEndPoint = "/api/ecommercePaymentMethods";
export const OrderInvoiceEndPoint = "/api/orders";
export default baseURL;
