import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "./../../components/utility/navbar/navbar";
import Footer from "../../components/utility/Footer/Footer";
import parse from "html-react-parser";
import { ToastContainer } from "react-bootstrap";
import { toast } from "react-toastify";
import { useGetCmsPageByIdQuery } from "../../RTK/Api/CMSApi";
import Loader from "../../components/Loaders/Loader";
import { Helmet } from "react-helmet";

const CMSPage = () => {
  const { id } = useParams();
  const { data, error, isLoading, refetch } = useGetCmsPageByIdQuery(id);
  if (error) toast.error(error);

  useEffect(() => {
    refetch();
    window.scrollTo(0, 0);
  }, [id]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <Helmet>
          <title>{data?.data?.title} - Smart E-commerce</title>
        </Helmet>
        <Navbar />
        <div className="text-start container" style={{ marginTop: "12%" }}>
          <h1 className="mb-3">{data?.data?.title}</h1>
          <p>{parse(data?.data?.description)}</p>
        </div>
        <Footer />
        <ToastContainer autoClose={2000} />
      </>
    );
  }
};

export default CMSPage;
