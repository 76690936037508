import { useEffect, useState } from "react";
import { useGetCompanyInfoQuery } from "../../RTK/Api/CompanyInfoApi";
import Cookies from "js-cookie";
import { useGetCurrenciesQuery } from "../../RTK/Api/CurrencyApi";

const GetCompanyInfo = () => {
  const { data: companyInfo } = useGetCompanyInfoQuery();
  const { data: currencyInfo } = useGetCurrenciesQuery();
  const [companyData, setCompanyData] = useState(null);
  const [companyCurrency, setCompanyCurrency] = useState(null);

  useEffect(() => {
    if (companyInfo && companyInfo.data.length > 0) {
      setCompanyData(companyInfo.data[0]);
      // setCompanyCurrency(companyInfo.currency[0]);
      if (currencyInfo) {
        setCompanyCurrency(
          currencyInfo?.data?.find((item) => item.currencyCode === "₺")
        );
      }
    }
  }, [companyInfo]);

  useEffect(() => {
    if (companyData) {
      Cookies.set("CompanyData", JSON.stringify(companyData));
      Cookies.set("CompanyCurrency", JSON.stringify(companyCurrency) || {});
    }
  }, [companyData]);

  return { companyData };
};

export default GetCompanyInfo;
