import React from "react";
import OfferSliderHook from "../../../hooks/HomePage/OfferSliderHook";
import { toast } from "react-toastify";
import { ServerBaseUrl } from "../../../api/GlobalData";

const MobileHomeSlider = () => {
  const { sliderInfo, sliderIsLoading, sliderError } = OfferSliderHook();
  if (sliderError) {
    console.error(sliderError);
    toast.error(sliderError);
  }

  return (
    <div className="HomeSlider">
      <swiper-container
        className="mobileSlider"
        pagination="true"
        pagination-dynamic-bullets="true"
        loop="true"
        autoplay={{ delay: 3000 }}
      >
        {!sliderIsLoading
          ? sliderInfo?.data?.length > 0
            ? sliderInfo?.data[0].images.map((slide, i) => {
                return (
                  <swiper-slide key={i}>
                    <img
                      src={ServerBaseUrl + "slider/" + slide}
                      style={{ objectFit: "contain" }}
                      alt=""
                    />
                  </swiper-slide>
                );
              })
            : null
          : null}
      </swiper-container>
    </div>
  );
};

export default MobileHomeSlider;
