import React, { useState } from "react";
import "./CategoryTree.css";
import { DashSquareFill, PlusSquareFill } from "react-bootstrap-icons";
import TransHook from "./../../../hooks/translation/trans-hook";

const CategoryTree = ({ categories }) => {
  const { t } = TransHook();
  const [expandedNodes, setExpandedNodes] = useState({});

  const handleToggle = (id) => {
    setExpandedNodes((prevExpandedNodes) => ({
      ...prevExpandedNodes,
      [id]: !prevExpandedNodes[id],
    }));
  };
  const lang = localStorage.getItem("language");

  const renderTreeNodes = (category) => (
    <li key={category?._id}>
      <div
        className="tree-node-header"
        onClick={() => handleToggle(category._id)}
      >
        <span className="tree-node-label">
          {lang == "ar"
            ? category?.nameAR
            : lang == "tr"
            ? category?.nameTR
            : category?.name}
        </span>
        {category?.childrenDetails && category?.childrenDetails?.length > 0 && (
          <span className="toggle-icon">
            {expandedNodes[category?._id] ? (
              <DashSquareFill color="#888" />
            ) : (
              <PlusSquareFill color="#888" />
            )}
          </span>
        )}
      </div>
      {category?.childrenDetails &&
        category?.childrenDetails?.length > 0 &&
        expandedNodes[category?._id] && (
          <ul className="tree-children">
            {category?.childrenDetails?.map((child) => renderTreeNodes(child))}
          </ul>
        )}
    </li>
  );

  return (
    <div className="category-tree-container">
      <h3>{t("categories")}</h3>
      <ul className="tree-root">
        {categories?.map((category) => renderTreeNodes(category))}
      </ul>
    </div>
  );
};

export default CategoryTree;
