import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import baseURL, { CurrencyEndPoint, DataBaseName } from "../../api/GlobalData";

export const currencyInfoApi = createApi({
  reducerPath: "currencyInfoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
  }),
  tagTypes: ["CurrencyInfo"],
  endpoints: (builder) => ({
    getCurrencies: builder.query({
      query: () => `${CurrencyEndPoint}?databaseName=${DataBaseName}`,
      providesTags: ["CurrencyInfo"],
    }),
  }),
});

export const { useGetCurrenciesQuery } = currencyInfoApi;
