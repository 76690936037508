import { useCallback, useEffect, useState } from "react";
import { useGetAllReviewsQuery } from "../../RTK/Api/ReviewApi";
import { toast } from "react-toastify";
import { Star, StarFill, StarHalf } from "react-bootstrap-icons";

const AllReviewsHook = (id) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [reviewList, setReviewList] = useState([]);
  const [rateCount, setRateCount] = useState(0);
  const [hasMore, setHasMore] = useState(true); // New state to track if there's more data

  const {
    data: reviews,
    isLoading,
    error,
    refetch,
  } = useGetAllReviewsQuery({ id, limit, skip });

  const loadMoreReviews = useCallback(() => {
    if (!isLoading && hasMore) {
      setLoadingMore(true);
      setSkip((prevSkip) => prevSkip + limit);
      setLimit(limit + 20);
    }
  }, [limit, isLoading, hasMore]);
  const [starsCount, setStarsCount] = useState([]);
  const [starsPercentage, setStarsPercentage] = useState([]);
  const [overallRatings, setOverallRating] = useState();
  useEffect(() => {
    if (reviews?.data) {
      setReviewList((prevList) => [...prevList, ...reviews.data]);
      setLoadingMore(false);
      setRateCount(reviews?.results);

      const stars = [0, 0, 0, 0, 0];

      reviews.data.forEach((review) => {
        if (review.rating >= 1 && review.rating <= 5) {
          stars[review.rating - 1]++;
        }
      });

      setStarsCount(stars);

      const percentages = stars.map((count) => (count / reviews.results) * 100);
      setStarsPercentage(percentages);

      const overallRating =
        (1 * stars[0] +
          2 * stars[1] +
          3 * stars[2] +
          4 * stars[3] +
          5 * stars[4]) /
        reviews.results;
      setOverallRating(overallRating);

      // Check if there's more data to load
      if (reviews.data.length < limit) {
        setHasMore(false);
      }
    }
  }, [reviews]);

  useEffect(() => {
    refetch();
  }, [skip, refetch]);

  if (error) {
    toast.error(error);
    console.error(error);
  }

  const renderStars = (ratings = overallRatings, size = 30) => {
    const stars = [];
    const fullStars = Math.floor(ratings);
    const hasHalfStar = ratings - fullStars >= 0.5;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarFill key={i} color="#fb1" size={size} />);
    }

    if (hasHalfStar) {
      stars.push(<StarHalf key="half" color="#fb1" size={size} />);
    }

    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Star key={`empty-${i}`} color="#fb1" size={size} />);
    }

    return stars;
  };

  return {
    reviewList,
    isLoading,
    rateCount,
    starsCount,
    starsPercentage,
    overallRatings,
    renderStars,
    refetch,
    loadMoreReviews,
    hasMore, // Return hasMore state
  };
};

export default AllReviewsHook;
